#root {
  color: black;
  background-color: #ecc4bc !important;
}

body {
  margin: 0;
  font-family: 'Share Tech Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay !important;
}

code {
  font-family: 'Share Tech Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select {
  margin-left: 10px;
}

#mobile.row {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}