.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background-color: white;
}

h1 {
  font-size: '4rem';
  margin: '3rem';
  background-color: 'white';
  border-radius: '5%'
}

#root {
  min-height: 100vh;
  background-color: #282c34;
  font-family: 'Share Tech Mono';
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  height: .4rem;
  width: .6rem;
}

::-webkit-scrollbar-track {
  background: #9e9e9ebe;
  border-radius: 100vw;
  margin-inline: 5em;
  margin-block: 2em
}

::-webkit-scrollbar-thumb {
  background: #525252d7;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: #3b3b3b;
}

.navbar {
  background-color: rgb(0, 115, 255);
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  box-shadow: .5rem .5rem #3b3b3be1;
}

.nav-link {
  font-size: 2rem;
}

.nav-item {
  margin-right: 2rem
}

.card {
  background-color: #F7EFE6 !important;
  color: #212529 !important;
  padding: 1vw;
  height: 100%;
  border-radius: 1rem !important;
}

.comment-card {
  background-color: #fff8f0 !important;
}

.card-header {
  padding-bottom: 0 !important;
}

.card img {
  width: 100%;
  object-fit: contain;
  background-color: black;
}

.custom-card {
  background-color: #ffffff !important;
  color: #212529;
  align-items: center;
  position: absolute !important;
  height: auto;
  top: 2.4rem;
  overflow-y: scroll;
}

.mob-blog-card {
  background-color: #ffffff !important;
  color: #212529;
  align-items: center;
  position: absolute !important;
  top: 0;
  overflow-y: scroll;
}

.carousel-control{
      width: 10%;
}

.react-multiple-carousel__arrow {
  background: rgb(236, 196,188, 89%) !important;
}

.react-multiple-carousel__arrow:hover {
  background: rgb(255, 208, 199) !important;
}

.react-multiple-carousel__arrow::before {
  color: black !important;
}

.main {
  transform: translateX(100vw);
  opacity: 0;
  transition: 200ms ease
}

.main.show {
  transform: translateX(0);
  opacity: 1;
}

.form-control {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form-bg {
  background-color: rgba(224, 242, 255,.9);
  border-radius: 1rem
}

.blog-card {
  height: 60vh;
}

.blog-text {
  resize: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
